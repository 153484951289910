var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.ldg,"disabled":_vm.ldg}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","plain":"","icon":"","to":{
                name: 'restitutions',
              }}},on),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Átras')}})]),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(_vm.$route.meta.title)}})],1),(_vm.data)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}],null,false,486583052)},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})]),(_vm.data.paid_date == null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"warning"},on:{"click":function($event){$event.preventDefault();return _vm.insurancePaidDlg.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v(" mdi-currency-usd ")])],1)]}}],null,false,730998271)},[_c('span',{domProps:{"textContent":_vm._s('Reg. pago')}})]):_vm._e()],1):_vm._e()],1)],1),(_vm.data)?_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Restitución","value":_vm.data.folio}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Tipo","value":_vm.data.type}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Creación","value":_vm.data.created_at}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Creado por","value":_vm.data.created_by.email}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Contratante","value":_vm.data.contractor.name}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('span',{staticClass:"text-caption font-weight-bold",domProps:{"textContent":_vm._s('Folio Fact.')}}),_c('br'),_c('span',{domProps:{"textContent":_vm._s(_vm.data.stamp_folio)}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":"","x-small":"","color":"cyan darken-3","dark":"","loading":_vm.btn_ldg},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(_vm.data.stamp_folio, 'pdf')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-download ")])],1)]}}],null,false,3468360448)},[_c('span',{domProps:{"textContent":_vm._s('Factura PDF')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":"","x-small":"","color":"teal darken-3","dark":"","loading":_vm.btn_ldg},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(_vm.data.stamp_folio, 'xml')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-xml ")])],1)]}}],null,false,2261062788)},[_c('span',{domProps:{"textContent":_vm._s('Factura XML')}})])],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Monto","value":_vm.numberFormat(_vm.data.amount)}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Registros","value":_vm.data.restitution_items.length}})],1),(_vm.data.paid_date)?_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"F. pago","value":_vm.data.paid_date ? _vm.data.paid_date : 'NO REG.'}})],1):_vm._e(),(_vm.data.paid_date)?_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Payment ID","value":_vm.data.payment_id ? _vm.data.payment_id : 'NO REG.'}})],1):_vm._e(),(_vm.data.paid_date)?_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('span',{staticClass:"text-caption font-weight-bold",domProps:{"textContent":_vm._s('Folio C. Pago')}}),_c('br'),_c('span',{domProps:{"textContent":_vm._s(_vm.data.stamp_receipt_folio)}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":"","x-small":"","color":"cyan darken-1","dark":"","loading":_vm.btn_ldg},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(_vm.data.stamp_receipt_id, 'pdf')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-download ")])],1)]}}],null,false,2606131119)},[_c('span',{domProps:{"textContent":_vm._s('C. pago PDF')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":"","x-small":"","color":"teal darken-1","dark":"","loading":_vm.btn_ldg},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(_vm.data.stamp_receipt_id, 'xml')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-xml ")])],1)]}}],null,false,219405771)},[_c('span',{domProps:{"textContent":_vm._s('C. pago XML')}})])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","hide-details":"","dense":""},model:{value:(_vm.tbl_srch),callback:function ($$v) {_vm.tbl_srch=$$v},expression:"tbl_srch"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.tbl_hdrs,"search":_vm.tbl_srch,"items":_vm.data.restitution_items,"items-per-page":15,"dense":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('b',{domProps:{"textContent":_vm._s(index + 1)}})]}},{key:"item.insured_full_name",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('span',{class:item.insured_id ? '' : 'orange--text'},[_vm._v(" "+_vm._s(item.insured_full_name)+" ")])]}},{key:"item.amount",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFormat(item.amount))+" ")]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return undefined}}],null,false,2077517476)})],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"300px"},model:{value:(_vm.insurance_paid_dlg),callback:function ($$v) {_vm.insurance_paid_dlg=$$v},expression:"insurance_paid_dlg"}},[_c('v-card',{attrs:{"tile":"","disabled":_vm.insurance_paid_dlg_ldg,"loading":_vm.insurance_paid_dlg_ldg}},[_c('v-toolbar',{attrs:{"dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v(" Pago aseguradora ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.insurance_paid_dlg = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1),(_vm.insurance_paid)?_c('v-card-text',[_c('v-form',{ref:"insurance_paid_form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',{staticClass:"pt-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('DatePicker',{attrs:{"label":"F. Pago","model":_vm.insurance_paid.paid_date,"rules":_vm.rules.required},on:{"update:model":function($event){return _vm.$set(_vm.insurance_paid, "paid_date", $event)}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Payment ID*","type":"text","dense":"","rules":_vm.rules.required,"maxlength":"20","counter":""},model:{value:(_vm.insurance_paid.payment_id),callback:function ($$v) {_vm.$set(_vm.insurance_paid, "payment_id", $$v)},expression:"insurance_paid.payment_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","dark":"","small":"","color":"success"},on:{"click":function($event){$event.preventDefault();return _vm.insurancePaidSave.apply(null, arguments)}}},[_vm._v(" Enviar "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" mdi-send ")])],1)],1)],1)],1)],1):_vm._e()],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }